.main {
    background-color: transparent;
    margin-left: -0.25rem;
}

.main button {
    border: none;
    outline: none;
    background-color: transparent;
}

.btnWrapper {
   height: 1rem;
   display: inline;
}

.btnWrapper img {
    margin-bottom: 0.1rem;
}

.btnWrapper p {
    display: inline;
}