

.card {
    width: max-content;
    padding: 3rem;
    overflow: hidden;
    border-radius:4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}