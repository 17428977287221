.main {
  width: 100%;
  height: max-content;
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  height: 3rem;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 3px;
  padding-left: 0.4rem;
  box-sizing: border-box;
  font-size: 0.9rem;
  box-shadow: 0px 1px 2px #0000001f;
}

.body {
  margin-top: 1rem;
  height: 8rem;
  width: 92%;
}

.text {
  width: 100%;
  height: 75%;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0.3rem;
  overflow-y: auto;
  color: grey;
  white-space: pre-wrap;
}

.editingText {
  width: 100%;
  height: 75%;
}

.editingText > textarea {
  width: 100%;
  height: calc(100% - 1.5rem);
  border: 1px solid black;
  border-radius: 3px;
  padding: 0.3rem;
  overflow-y: auto;
}

.editingBtn {
  width: 100%;
  height: 1.5rem;
  text-align: center;
}

.editingBtn > button {
  height: 1rem;
  margin: 0.25rem;
  background-color: #eaeaea;
  border: none;
  outline: none;
}

.details {
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: grey;
}

.detailsMain {
  width: 50%;
  height: 100%;
}

.headerLeft {
  width: 2.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.headerMiddle {
  width: calc(60% - 2.5rem);
  height: 100%;
  padding-top: 0.3rem;
}

.headerRight {
  width: 35%;
  height: 100%;
  padding: 0.1rem;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerRight svg {
  padding: 5px;
}

.headerRight > span {
  font-size: 0.75rem;
  color: black;
  font-style: italic;
}

.header span {
  margin: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.headerMiddle p {
  margin: 0;
  color: black;
  font-size: 0.85rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

p.firstComment {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.72rem;
}
