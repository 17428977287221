.main {
    width: max-content;
    height: max-content;
    min-width: 10rem;
    min-height: 10rem;
    max-width: 16rem;
    display: flex;
    flex-direction: column;
}

.message {
    margin-bottom: 1rem;
}

.message p b{
    word-break: break-all;
}

.inputBox{
    width: 100%;
    display: flex;
    align-items: center;
}

.inputBox button {
    background-color: transparent;
    color: var(--btn-primary-color);
    border: none;
    outline: none;
}

.inputBox button:hover {
    color: rgb(30 182 255);
}

.inputBox input {
    flex: 1;
}

.inputBox .resend {
    margin-left: 0.75rem;
}

.main p:nth-child(3) {
    color: var(--btn-primary-color);
    font-size: 0.75rem;
    padding-top: 0.5rem;
}


.actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.needHelp {
    width: 100%;
}