

.main {
   height: 70px;
}

.main:hover {
   background-color: var(--panel-highlight-color);
}

.main td {
   border-bottom: 1px solid black;
   padding: 5px;
   width: 1%;
   white-space: nowrap;
}

.main td:nth-child(1){
    padding-left: 10px;
}

.nameField {
   white-space: nowrap;
   max-width: 12.5rem;
   overflow: hidden;
   text-overflow: ellipsis;
}

.projectNameContainer {
   display: flex;
   align-items: center;
}

.imageContainer {
   width: 3rem;
   height: 3rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   margin-right: 0.25rem;
}

.projectName {
   flex: 1;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.actions{
   display: flex;
}
