.imagePreview {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  position: relative;
}

.imagePreview svg:hover {
  cursor: pointer;
}

.overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  z-index: 10;
}

.draggable * {
  cursor: move;
}

.disabled {
	pointer-events: none;
}