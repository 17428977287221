.content {
    display: flex;
    flex-direction: column;
}

.content > * {
    margin-bottom: 1rem;
}

.content label {
    margin-bottom: 0.5rem;
}

.content input {
    background-color: rgb(230, 231, 232);
    color: black;
    border: none;
    padding-left: 0.5rem;
    height: 2rem;
}

.content input:focus {
    outline: none;
}