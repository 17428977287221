

.mainBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    font-size: 0.75rem;
    background-color: var(--panel-semi-dark-background);
    border-radius: 8px;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 17rem;
}

.heading {
    width: 100%;
}

.heading h2 {
    text-align: left;
    margin: 0;
    font-size: var(--heading-font-size);
    font-weight: var(--heading-font-weight);
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.container label {
    margin-top: 1rem;
}

.passwordStrengthBarContainer {
    width: 100%;
}

.actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    gap: 12px;
}

.signIn {
    margin-top: 1rem;
    text-align: center;
}

.needHelp {
    margin-top: 0.5rem;
    text-align: center;
    width: 100%;
}

.eula {
    display: flex;
    justify-items: center;
}

.eulaContainer {
    height: 75vh;
    width: 75vw;
}

.modelDialog  :global(.MuiDialog-paperWidthSm) {
    max-width: 90vw;
}

.eulaContainer > div {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}
.eulaContainer iframe {
    background: white;
    height: 100%;
    width: 100%;
}
.checkbox {
    width: 16px;
    margin-right: 6px;
}