.main {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 1px 2px #0000001f;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
}

.commentBox {
  width: 100%;
  height: 3rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  resize: none;
  font-family: monospace;
  font-size: 0.85rem;
  line-height: normal;
  box-sizing: content-box;
}

.commentBox:focus {
  outline: 0;
}

.commentBox:empty {
  font-style: italic;
}

.btn {
  margin-top: 0.5rem;
  width: 100%;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
}

.btn button {
  width: 1rem;
  height: 100%;
  margin-left: 1rem;
  border-radius: 0.2rem;
  border: none;
}

.leftBtnContainer {
  height: 100%;
  width: 20px;
  text-align: left;
}

.rightBtnContainer {
  height: 100%;
  width: calc(100% - 20px);
  text-align: right;
}
