

.toolpanel-button {
	color: var(--text-light-color);
	text-align: center;
	font-family: 'FontAwesome';
	font-size: 28px !important;
	display: inline-block;
	border-radius: 4px;
}

.toolpanel-button:hover {
	background-color : rgb(96, 96, 96);
}

.x-btn-disabled{
	opacity: 0.4;
	pointer-events: none;
}

.x-btn-pressed {
	background-color: var(--panel-semi-light-background);
	color: #fff;
	border-color: var(--header-color);
}
