.colorSelector {
    display: inline-block;
    font-size: 0;
    border: 1px solid #cbced0;
    height: 1.5rem;
    border-radius: 50%;
    width: 1.5rem;
}
.colorSelector .menu{
    left: 0;
    top: 0;
    transform: none;
    z-index: 99;
    width: max-content;
}

.fillColorSelector {
    vertical-align: middle;
    margin-left: 10px;
}

.strokeWidthController { 
    display: flex;
    justify-content: space-around;
    padding: 3px 0;
}
.strokeColorSelector {
    margin-right: 15px;
    min-width: 1.5rem;
    margin-left: 2px;
}
.strokeDelete {
    vertical-align: super;
    width: 24px;
    height: 18px;
    border-radius: 5px;
    color: #cecfd1;
    margin-left: 15px;
    transform: translateY(25%);
}

.strokeSlider{
    margin-top: 3px;
}