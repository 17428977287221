

div.action-panel {
	padding: 0;
    width: max-content;
    text-align: center;
}
.action-panel.separator-top {
    padding-top: 6px;
    border-top: 1px solid rgb(157, 159, 160);
}
.x-panel-header-title-default {
	user-select: none;
}
.tool-panel-container {
	width: 100%;
	border-right: 1px solid #9d9fa0;
}

.text-view-btn-style1 {
	width: 45px;
	height: 42px;
	position: relative;
}

.text-view-btn-style2 {
	width: 70px;
	height: 50px;
	position: relative;
}

.img-curved-text-inside {
	background-image: url('../../resources/images/CurvedTextPlacementInside.svg')!important;
	background-repeat: no-repeat!important;
	background-position: center;
	background-size: 90px;
}

.img-curved-text-outside {
	background-image: url('../../resources/images/CurvedTextPlacementOutside.svg')!important;
	background-repeat: no-repeat!important;
	background-position: center;
	background-size: 90px;
}

.zoom-view-btn-style {
	width: 100%;
	text-align: left !important;
    padding: 5px 10px;
	margin-bottom: 10px;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.dark-background {
	background-color: var(--panel-dark-background);
}

.semi-light-background {
	background-color: var(--panel-semi-light-background);
}

.semi-dark-background {
	background-color: var(--panel-semi-dark-background);
}

.font-22 {
	font-size: 22px !important;
}
.font-28 {
	font-size: 28px !important;
}

div.MuiOutlinedInput-input {
    padding: 12px 14px !important;
}

.image-item-thumb-wrap.x-item-selected {
	outline: 3px solid var(--selection-color);
	border-radius: 2px;
}

.texture.x-item-selected {
	outline: 3px solid var(--selection-color);
	border-radius: 2px;
}

.clipart-category-btn {
	border: none;
    width: 24px;
    height: 18px;
    border-radius: 5px;
	color: #cecfd1;
}

.clipart-category-btn:hover {
	color: #fff;
}

.edit-svg {
	position: absolute;
    left: 0;
    top: 0;
    width: 100%;
	background-color: #ddd;
	padding: 5px;
}

.hyperlink {
	color: blue;
	cursor: pointer;
}
.hyperlink:hover {
	text-decoration: underline;
}

.manage-art-item {
    background-color: gray;
    display: inline-block;
    padding: 8px;
    margin: 5px;
    border-radius: 10%;
}

.manage-art-item.x-item-selected {
	outline: 3px solid #389ae8;
}

span.color-item-thumb-wrap {
	position: relative;
	margin: 0px 2px;
}

span.color-item-thumb-wrap img.locked {
    position: absolute;
    background-color: black;
	border: 2px black solid;
    width: 0.7rem;
    top: 0;
    left: 0;
}

span.color-item-thumb-wrap img.mesh {
    position: absolute;
    background-color: black;
    width: 0.7rem;
	height: 0.8rem;
    top: 0;
    left: 0.7rem;
}

.MuiDialog-root span {
	cursor: default;
	user-select: none;
}

.MuiOutlinedInput-root.MuiOutlinedInput-multiline {
    padding: 10px;
}

.MuiSlider-valueLabel span span{
	color: #0e1318;
}

.bi-end-Slider.second .MuiSlider-thumbColorPrimary[data-index="1"],
.bi-end-Slider.first .MuiSlider-thumbColorPrimary[data-index="0"] {
	display: none;
}

.MuiInputBase-root.MuiOutlinedInput-root {
	border-radius: 0;
}

.MuiTabs-root > div > span {
	background-color: rgb(251, 180, 22);
}

.MuiDialog-paper.MuiPaper-root {
	background-color: var(--panel-semi-dark-background);
	padding: 0.5rem;
}
.MuiSvgIcon-root,
.MuiDialogTitle-root {
	color: #cecfd1;
}

div.image-item-thumb-wrap.shapeItem {
	width: 101px;
    height: 71px;
    background-color: transparent;
    border: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
	margin: 5px;
}

.editor-text {
	font-size : 14px !important;
	font-family: 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif !important;
	color: var(--text-light-color);
}

.editor-font-color {
	color: var(--text-light-color);
}

/*ws-button css  */

.icomoon {
	font-family: 'icomoon';
}

.icomoon2 {
	font-family: 'icomoon2'!important;
}

.icomoon5 {
	font-family: 'icomoon5'!important;
}

.previewCanvasContainer {
	border: 1px solid #9d9fa0;
	box-sizing: content-box;
	background: var(--panel-semi-dark-background);
	text-align: center;
}

.previewCanvasLabel {
	font-size: 10px;
	border-top: 1px solid #9d9fa0;
	font-weight: normal;
	user-select: none;
}

/*  */

div.image-item-thumb-wrap {
	width: 120px;
	height: 120px;
	position: relative;
	display: inline-block;
	background-color: white;
	margin-bottom: 3px;
	border: 1px solid #cbced0;
	user-select: none;
}

div.image-item-thumb-wrap .image-item-thumb {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	object-fit: cover;
}

div.image-item-text-wrap {
	margin-left: -15px;
}

div.clipart-item-thumb-wrap {
	width: 80px;
	height: 80px;
	display: inline-block;
	margin-bottom: 3px;
	padding: 2px;
	user-select: none;
}

div.clipart-item-thumb-wrap img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

span.color-item-thumb-wrap {
	height: 36px;
	width: 36px;
	display: inline-block;
	padding: 2px;
	user-select: none;
}

span.color-item-thumb-wrap span {
	display: block;
	font-size: 0;
	border: 1px solid black;
	height: 32px;
	border-radius: 3px;
	width: 34px;
}

span.color-item-thumb-wrap.x-item-selected span {
	outline: 3px solid var(--selection-color);
}

.color-added-notification{
	margin-right: auto;
}

.project-saved-notification{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}