#root {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

:root {
    --label-margin-bottom: 0.5rem;
    --header-color: rgb(251, 180, 22);
    --panel-semi-dark-background: rgb(41, 48, 57);
    --panel-dark-background: rgb(14, 19, 24);
    --panel-light-background: rgb(81, 81, 81);
    --panel-semi-light-background: rgb(25, 33, 42);
    --text-light-color: rgb(205, 206, 208);
    --border-light-color: rgb(205, 206, 208);
    --btn-primary-color: rgb(70, 158, 246);
    --btn-negative-color: rgb(238, 31, 41);
    --btn-positive-color: rgb(94, 190, 123);
    --panel-highlight-color: dimgray;
    --heading-font-size: 1.3rem;
    --heading-font-weight: 400;
    --selection-color: rgb(251, 180, 22);
    --chat-list-background: rgb(225,225,225)
}

body {
    margin: 0px;
    font-family: 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    user-select: none;
    background-color: var(--panel-dark-background);
    color: var(--text-light-color);
}

.MuiSlider-root {
	color: #fff !important;
}

.MuiDialogActions-root {
    padding: 1.5rem !important;
}

input.MuiInputBase-input {
    padding-left: 8px;
}

span.MuiSlider-valueLabel {
    left: calc(-50% + 2px);
    top: -15px;
}

span.MuiSlider-valueLabel > span {
    font-size: 0.5rem;
    width: 20px;
    height: 20px;
}

.x-item-disabled {
	opacity: 0.4;
	pointer-events: none;
}

.align-center {
	position: absolute;
    top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
}

.align-vertical-center {
	position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.align-horizontal-center {
	position: absolute;
	left: 50%;
    transform: translateX(-50%);
}

.scroll-vertical {
	overflow-y: auto;
	overflow-x: hidden;
}

.hidden {
    display: none;
}

.text-60 {
    font-size: 60px !important;
}

::-webkit-scrollbar {
    width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
    background: rgb(179, 177, 177);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgb(136, 136, 136);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
    background: rgb(68, 68, 68);
    border-radius: 10px;
}

div{
    scrollbar-width: thin;
}

button:focus {
    outline: none;
}

.react-switch-handle {
    background-color: var(--btn-primary-color) !important;
}

.react-switch-handle {
    box-shadow: none !important;
}

.cursor-pointer{
    cursor: pointer;
}

.MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 12px !important;
}