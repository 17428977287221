

.toolbar-button {
	height: 50px;
    padding-left: 0;
	padding-right: 0;
    margin-bottom: 10px;
    text-align: center;
	border-left: 4px solid transparent;
	box-sizing: border-box;
}

.x-btn-pressed {
	background-color: var(--panel-semi-dark-background);
	color: #fff;
	border-color: var(--header-color);
}