.clipartItemThumbWrap{
    border-radius: 5px;
    padding: 5px;
    position: relative;
    display: inline-block;
    background-color: #999999;
    user-select: none;
    margin: 5px;
    width: 60px;
    height: 60px;
}

.clipartImage {
    object-fit: contain;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}