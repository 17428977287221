

.userProfileContent {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    width: 16.5rem;
}

.userProfileContent >* {
    margin-bottom: 1rem;
}

.userProfileContent > label {
    margin-bottom: 0.5rem;
}

.userRole {
    display: flex;
}

.userRole label {
    margin: 0;
}

.userRole input:focus-visible {
    outline: none;
}

.userSelector {
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.userSelector input, .userSelector label {
    margin: 0;
    margin-right: 0.5rem;
}

.userProfileActions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.userProfileActions.fromSignIn {
    justify-content: space-between;
}

.userProfileActions > button:nth-child(2) {
    margin-left: 12px;
}

.userProfileActions .skip {
    align-self: center;
}