.main {
  padding-top: 0.8rem;
  padding-left: 1.25rem;
  padding-right: 5%;
  padding-bottom: 2rem;
  width: 100vw;
  height: calc(100vh - 3rem);
  display: flex;
  justify-content: space-between;
  background-color: var(--panel-dark-background);
  box-sizing: border-box;
}

@media (orientation: landscape) {
  .main {
    flex-direction: row;
  }

  .leftContainer {
    flex: 7 1;
    height: 100%;
  }

  .rightContainer {
    height: 100%;
    flex: 1 0;
    min-width: 19rem;
  }
}

@media (orientation: portrait) {
  .main {
    flex-direction: column;
  }

  .leftContainer {
    width: 100%;
    height: 35%;
  }
  
  .rightContainer {
    width: 100%;
    height: 65%;
  }
}

.actionContainer {
  margin-top: 10px;
  float: right;
}

.approveDialogContainer {
  padding: 1.25rem 0 0.75rem 0;
  background-color: var(--panel-semi-light-background);
}

.approveDialogContainer > ol > li {
  margin-bottom: 1.5rem;
}

.approveDialogContainer ul > li, .approveDialogContainer li > div {
  margin-bottom: 0.8rem;
}

.approveDialogContainer ul {
  list-style-type: disc;
}

.approveDialogFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.approveDialogFooter button:nth-child(2){
  margin-left: 12px;
}

.approveDialogNote {
  display: flex;
  align-items: end;
  font-size: 0.7rem;
  color: var(--text-light-color);
}