.x-mask {
  opacity: 0.5;
}
.x-masked-relative {
  position: relative;
}
.x-mask {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none !important;
}
.x-ie8 .x-mask {
  background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}
.x-mask-fixed {
  position: fixed;
}
.x-mask-msg {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.x-mask {
  background-image: none;
  background-color: rgba(208, 208, 208, 0.5);
  cursor: default;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
.x-ie8 .x-mask {
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#80d0d0d0, endColorstr=#80d0d0d0)";
  zoom: 1;
}
body > .x-mask {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.75);
}
.x-ie8 body > .x-mask {
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#bf000000, endColorstr=#bf000000)";
  zoom: 1;
}
.x-keyboard-mode .x-mask.x-focus {
  border-style: solid;
  border-width: 1px;
  border-color: #5fa2dd;
}
.x-mask-msg {
  padding: 10px;
  background: #d0d0d0;
}
.x-mask-msg-inner {
  padding: 0;
  background-color: transparent;
  color: #404040;
  font: 300 13px "Open Sans", "Helvetica Neue", helvetica, arial, verdana,
    sans-serif;
}
.x-mask-msg-text {
  padding: 25px 0 0;
  background-image: url(images/loadmask/loading.gif);
  background-repeat: no-repeat;
  background-position: center 0;
}
.x-panel-light.x-masked {
  border-color: #e8e8e8;
}
.x-panel-light-framed.x-masked {
  border-color: #e8e8e8;
}
@font-face {
  font-family: "FontAwesome";
  src: url("font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0")
      format("embedded-opentype"),
    url("font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"),
    url("font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"),
    url("font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"),
    url("font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14286em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14286em;
  width: 2.14286em;
  top: 0.14286em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714em;
}
.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eee;
  border-radius: 0.1em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right {
  margin-left: 0.3em;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.fa.pull-left {
  margin-right: 0.3em;
}
.fa.pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
.fa-glass:before {
  content: "\f000" !important;
}
.fa-music:before {
  content: "\f001" !important;
}
.fa-search:before {
  content: "\f002" !important;
}
.fa-envelope-o:before {
  content: "\f003" !important;
}
.fa-heart:before {
  content: "\f004" !important;
}
.fa-star:before {
  content: "\f005" !important;
}
.fa-star-o:before {
  content: "\f006" !important;
}
.fa-user:before {
  content: "\f007" !important;
}
.fa-film:before {
  content: "\f008" !important;
}
.fa-th-large:before {
  content: "\f009" !important;
}
.fa-th:before {
  content: "\f00a" !important;
}
.fa-th-list:before {
  content: "\f00b" !important;
}
.fa-check:before {
  content: "\f00c" !important;
}
.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d" !important;
}
.fa-search-plus:before {
  content: "\f00e" !important;
}
.fa-search-minus:before {
  content: "\f010" !important;
}
.fa-power-off:before {
  content: "\f011" !important;
}
.fa-signal:before {
  content: "\f012" !important;
}
.fa-gear:before,
.fa-cog:before {
  content: "\f013" !important;
}
.fa-trash-o:before {
  content: "\f014" !important;
}
.fa-home:before {
  content: "\f015" !important;
}
.fa-file-o:before {
  content: "\f016" !important;
}
.fa-clock-o:before {
  content: "\f017" !important;
}
.fa-road:before {
  content: "\f018" !important;
}
.fa-download:before {
  content: "\f019" !important;
}
.fa-arrow-circle-o-down:before {
  content: "\f01a" !important;
}
.fa-arrow-circle-o-up:before {
  content: "\f01b" !important;
}
.fa-inbox:before {
  content: "\f01c" !important;
}
.fa-play-circle-o:before {
  content: "\f01d" !important;
}
.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e" !important;
}
.fa-refresh:before {
  content: "\f021" !important;
}
.fa-list-alt:before {
  content: "\f022" !important;
}
.fa-lock:before {
  content: "\f023" !important;
}
.fa-flag:before {
  content: "\f024" !important;
}
.fa-headphones:before {
  content: "\f025" !important;
}
.fa-volume-off:before {
  content: "\f026" !important;
}
.fa-volume-down:before {
  content: "\f027" !important;
}
.fa-volume-up:before {
  content: "\f028" !important;
}
.fa-qrcode:before {
  content: "\f029" !important;
}
.fa-barcode:before {
  content: "\f02a" !important;
}
.fa-tag:before {
  content: "\f02b" !important;
}
.fa-tags:before {
  content: "\f02c" !important;
}
.fa-book:before {
  content: "\f02d" !important;
}
.fa-bookmark:before {
  content: "\f02e" !important;
}
.fa-print:before {
  content: "\f02f" !important;
}
.fa-camera:before {
  content: "\f030" !important;
}
.fa-font:before {
  content: "\f031" !important;
}
.fa-bold:before {
  content: "\f032" !important;
}
.fa-italic:before {
  content: "\f033" !important;
}
.fa-text-height:before {
  content: "\f034" !important;
}
.fa-text-width:before {
  content: "\f035" !important;
}
.fa-align-left:before {
  content: "\f036" !important;
}
.fa-align-center:before {
  content: "\f037" !important;
}
.fa-align-right:before {
  content: "\f038" !important;
}
.fa-align-justify:before {
  content: "\f039" !important;
}
.fa-list:before {
  content: "\f03a" !important;
}
.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b" !important;
}
.fa-indent:before {
  content: "\f03c" !important;
}
.fa-video-camera:before {
  content: "\f03d" !important;
}
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\f03e" !important;
}
.fa-pencil:before {
  content: "\f040" !important;
}
.fa-map-marker:before {
  content: "\f041" !important;
}
.fa-adjust:before {
  content: "\f042" !important;
}
.fa-tint:before {
  content: "\f043" !important;
}
.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044" !important;
}
.fa-share-square-o:before {
  content: "\f045" !important;
}
.fa-check-square-o:before {
  content: "\f046" !important;
}
.fa-arrows:before {
  content: "\f047" !important;
}
.fa-step-backward:before {
  content: "\f048" !important;
}
.fa-fast-backward:before {
  content: "\f049" !important;
}
.fa-backward:before {
  content: "\f04a" !important;
}
.fa-play:before {
  content: "\f04b" !important;
}
.fa-pause:before {
  content: "\f04c" !important;
}
.fa-stop:before {
  content: "\f04d" !important;
}
.fa-forward:before {
  content: "\f04e" !important;
}
.fa-fast-forward:before {
  content: "\f050" !important;
}
.fa-step-forward:before {
  content: "\f051" !important;
}
.fa-eject:before {
  content: "\f052" !important;
}
.fa-chevron-left:before {
  content: "\f053" !important;
}
.fa-chevron-right:before {
  content: "\f054" !important;
}
.fa-plus-circle:before {
  content: "\f055" !important;
}
.fa-minus-circle:before {
  content: "\f056" !important;
}
.fa-times-circle:before {
  content: "\f057" !important;
}
.fa-check-circle:before {
  content: "\f058" !important;
}
.fa-question-circle:before {
  content: "\f059" !important;
}
.fa-info-circle:before {
  content: "\f05a" !important;
}
.fa-crosshairs:before {
  content: "\f05b" !important;
}
.fa-times-circle-o:before {
  content: "\f05c" !important;
}
.fa-check-circle-o:before {
  content: "\f05d" !important;
}
.fa-ban:before {
  content: "\f05e" !important;
}
.fa-arrow-left:before {
  content: "\f060" !important;
}
.fa-arrow-right:before {
  content: "\f061" !important;
}
.fa-arrow-up:before {
  content: "\f062" !important;
}
.fa-arrow-down:before {
  content: "\f063" !important;
}
.fa-mail-forward:before,
.fa-share:before {
  content: "\f064" !important;
}
.fa-expand:before {
  content: "\f065" !important;
}
.fa-compress:before {
  content: "\f066" !important;
}
.fa-plus:before {
  content: "\f067" !important;
}
.fa-minus:before {
  content: "\f068" !important;
}
.fa-asterisk:before {
  content: "\f069" !important;
}
.fa-exclamation-circle:before {
  content: "\f06a" !important;
}
.fa-gift:before {
  content: "\f06b" !important;
}
.fa-leaf:before {
  content: "\f06c" !important;
}
.fa-fire:before {
  content: "\f06d" !important;
}
.fa-eye:before {
  content: "\f06e" !important;
}
.fa-eye-slash:before {
  content: "\f070" !important;
}
.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071" !important;
}
.fa-plane:before {
  content: "\f072" !important;
}
.fa-calendar:before {
  content: "\f073" !important;
}
.fa-random:before {
  content: "\f074" !important;
}
.fa-comment:before {
  content: "\f075" !important;
}
.fa-magnet:before {
  content: "\f076" !important;
}
.fa-chevron-up:before {
  content: "\f077" !important;
}
.fa-chevron-down:before {
  content: "\f078" !important;
}
.fa-retweet:before {
  content: "\f079" !important;
}
.fa-shopping-cart:before {
  content: "\f07a" !important;
}
.fa-folder:before {
  content: "\f07b" !important;
}
.fa-folder-open:before {
  content: "\f07c" !important;
}
.fa-arrows-v:before {
  content: "\f07d" !important;
}
.fa-arrows-h:before {
  content: "\f07e" !important;
}
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080" !important;
}
.fa-twitter-square:before {
  content: "\f081" !important;
}
.fa-facebook-square:before {
  content: "\f082" !important;
}
.fa-camera-retro:before {
  content: "\f083" !important;
}
.fa-key:before {
  content: "\f084" !important;
}
.fa-gears:before,
.fa-cogs:before {
  content: "\f085" !important;
}
.fa-comments:before {
  content: "\f086" !important;
}
.fa-thumbs-o-up:before {
  content: "\f087" !important;
}
.fa-thumbs-o-down:before {
  content: "\f088" !important;
}
.fa-star-half:before {
  content: "\f089" !important;
}
.fa-heart-o:before {
  content: "\f08a" !important;
}
.fa-sign-out:before {
  content: "\f08b" !important;
}
.fa-linkedin-square:before {
  content: "\f08c" !important;
}
.fa-thumb-tack:before {
  content: "\f08d" !important;
}
.fa-external-link:before {
  content: "\f08e" !important;
}
.fa-sign-in:before {
  content: "\f090" !important;
}
.fa-trophy:before {
  content: "\f091" !important;
}
.fa-github-square:before {
  content: "\f092" !important;
}
.fa-upload:before {
  content: "\f093" !important;
}
.fa-lemon-o:before {
  content: "\f094" !important;
}
.fa-phone:before {
  content: "\f095" !important;
}
.fa-square-o:before {
  content: "\f096" !important;
}
.fa-bookmark-o:before {
  content: "\f097" !important;
}
.fa-phone-square:before {
  content: "\f098" !important;
}
.fa-twitter:before {
  content: "\f099" !important;
}
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a" !important;
}
.fa-github:before {
  content: "\f09b" !important;
}
.fa-unlock:before {
  content: "\f09c" !important;
}
.fa-credit-card:before {
  content: "\f09d" !important;
}
.fa-feed:before,
.fa-rss:before {
  content: "\f09e" !important;
}
.fa-hdd-o:before {
  content: "\f0a0" !important;
}
.fa-bullhorn:before {
  content: "\f0a1" !important;
}
.fa-bell:before {
  content: "\f0f3" !important;
}
.fa-certificate:before {
  content: "\f0a3" !important;
}
.fa-hand-o-right:before {
  content: "\f0a4" !important;
}
.fa-hand-o-left:before {
  content: "\f0a5" !important;
}
.fa-hand-o-up:before {
  content: "\f0a6" !important;
}
.fa-hand-o-down:before {
  content: "\f0a7" !important;
}
.fa-arrow-circle-left:before {
  content: "\f0a8" !important;
}
.fa-arrow-circle-right:before {
  content: "\f0a9" !important;
}
.fa-arrow-circle-up:before {
  content: "\f0aa" !important;
}
.fa-arrow-circle-down:before {
  content: "\f0ab" !important;
}
.fa-globe:before {
  content: "\f0ac" !important;
}
.fa-wrench:before {
  content: "\f0ad" !important;
}
.fa-tasks:before {
  content: "\f0ae" !important;
}
.fa-filter:before {
  content: "\f0b0" !important;
}
.fa-briefcase:before {
  content: "\f0b1" !important;
}
.fa-arrows-alt:before {
  content: "\f0b2" !important;
}
.fa-group:before,
.fa-users:before {
  content: "\f0c0" !important;
}
.fa-chain:before,
.fa-link:before {
  content: "\f0c1" !important;
}
.fa-cloud:before {
  content: "\f0c2" !important;
}
.fa-flask:before {
  content: "\f0c3" !important;
}
.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4" !important;
}
.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5" !important;
}
.fa-paperclip:before {
  content: "\f0c6" !important;
}
.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7" !important;
}
.fa-square:before {
  content: "\f0c8" !important;
}
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9" !important;
}
.fa-list-ul:before {
  content: "\f0ca" !important;
}
.fa-list-ol:before {
  content: "\f0cb" !important;
}
.fa-strikethrough:before {
  content: "\f0cc" !important;
}
.fa-underline:before {
  content: "\f0cd" !important;
}
.fa-table:before {
  content: "\f0ce" !important;
}
.fa-magic:before {
  content: "\f0d0" !important;
}
.fa-truck:before {
  content: "\f0d1" !important;
}
.fa-pinterest:before {
  content: "\f0d2" !important;
}
.fa-pinterest-square:before {
  content: "\f0d3" !important;
}
.fa-google-plus-square:before {
  content: "\f0d4" !important;
}
.fa-google-plus:before {
  content: "\f0d5" !important;
}
.fa-money:before {
  content: "\f0d6" !important;
}
.fa-caret-down:before {
  content: "\f0d7" !important;
}
.fa-caret-up:before {
  content: "\f0d8" !important;
}
.fa-caret-left:before {
  content: "\f0d9" !important;
}
.fa-caret-right:before {
  content: "\f0da" !important;
}
.fa-columns:before {
  content: "\f0db" !important;
}
.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc" !important;
}
.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\f0dd" !important;
}
.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\f0de" !important;
}
.fa-envelope:before {
  content: "\f0e0" !important;
}
.fa-linkedin:before {
  content: "\f0e1" !important;
}
.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2" !important;
}
.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3" !important;
}
.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4" !important;
}
.fa-comment-o:before {
  content: "\f0e5" !important;
}
.fa-comments-o:before {
  content: "\f0e6" !important;
}
.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7" !important;
}
.fa-sitemap:before {
  content: "\f0e8" !important;
}
.fa-umbrella:before {
  content: "\f0e9" !important;
}
.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea" !important;
}
.fa-lightbulb-o:before {
  content: "\f0eb" !important;
}
.fa-exchange:before {
  content: "\f0ec" !important;
}
.fa-cloud-download:before {
  content: "\f0ed" !important;
}
.fa-cloud-upload:before {
  content: "\f0ee" !important;
}
.fa-user-md:before {
  content: "\f0f0" !important;
}
.fa-stethoscope:before {
  content: "\f0f1" !important;
}
.fa-suitcase:before {
  content: "\f0f2" !important;
}
.fa-bell-o:before {
  content: "\f0a2" !important;
}
.fa-coffee:before {
  content: "\f0f4" !important;
}
.fa-cutlery:before {
  content: "\f0f5" !important;
}
.fa-file-text-o:before {
  content: "\f0f6" !important;
}
.fa-building-o:before {
  content: "\f0f7" !important;
}
.fa-hospital-o:before {
  content: "\f0f8" !important;
}
.fa-ambulance:before {
  content: "\f0f9" !important;
}
.fa-medkit:before {
  content: "\f0fa" !important;
}
.fa-fighter-jet:before {
  content: "\f0fb" !important;
}
.fa-beer:before {
  content: "\f0fc" !important;
}
.fa-h-square:before {
  content: "\f0fd" !important;
}
.fa-plus-square:before {
  content: "\f0fe" !important;
}
.fa-angle-double-left:before {
  content: "\f100" !important;
}
.fa-angle-double-right:before {
  content: "\f101" !important;
}
.fa-angle-double-up:before {
  content: "\f102" !important;
}
.fa-angle-double-down:before {
  content: "\f103" !important;
}
.fa-angle-left:before {
  content: "\f104" !important;
}
.fa-angle-right:before {
  content: "\f105" !important;
}
.fa-angle-up:before {
  content: "\f106" !important;
}
.fa-angle-down:before {
  content: "\f107" !important;
}
.fa-desktop:before {
  content: "\f108" !important;
}
.fa-laptop:before {
  content: "\f109" !important;
}
.fa-tablet:before {
  content: "\f10a" !important;
}
.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b" !important;
}
.fa-circle-o:before {
  content: "\f10c" !important;
}
.fa-quote-left:before {
  content: "\f10d" !important;
}
.fa-quote-right:before {
  content: "\f10e" !important;
}
.fa-spinner:before {
  content: "\f110" !important;
}
.fa-circle:before {
  content: "\f111" !important;
}
.fa-mail-reply:before,
.fa-reply:before {
  content: "\f3e5" !important;
}
.fa-github-alt:before {
  content: "\f113" !important;
}
.fa-folder-o:before {
  content: "\f114" !important;
}
.fa-folder-open-o:before {
  content: "\f115" !important;
}
.fa-smile-o:before {
  content: "\f118" !important;
}
.fa-frown-o:before {
  content: "\f119" !important;
}
.fa-meh-o:before {
  content: "\f11a" !important;
}
.fa-gamepad:before {
  content: "\f11b" !important;
}
.fa-keyboard-o:before {
  content: "\f11c" !important;
}
.fa-flag-o:before {
  content: "\f11d" !important;
}
.fa-flag-checkered:before {
  content: "\f11e" !important;
}
.fa-terminal:before {
  content: "\f120" !important;
}
.fa-code:before {
  content: "\f121" !important;
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122" !important;
}
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123" !important;
}
.fa-location-arrow:before {
  content: "\f124" !important;
}
.fa-crop:before {
  content: "\f125" !important;
}
.fa-code-fork:before {
  content: "\f126" !important;
}
.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127" !important;
}
.fa-question:before {
  content: "\f128" !important;
}
.fa-info:before {
  content: "\f129" !important;
}
.fa-exclamation:before {
  content: "\f12a" !important;
}
.fa-superscript:before {
  content: "\f12b" !important;
}
.fa-subscript:before {
  content: "\f12c" !important;
}
.fa-eraser:before {
  content: "\f12d" !important;
}
.fa-puzzle-piece:before {
  content: "\f12e" !important;
}
.fa-microphone:before {
  content: "\f130" !important;
}
.fa-microphone-slash:before {
  content: "\f131" !important;
}
.fa-shield:before {
  content: "\f132" !important;
}
.fa-calendar-o:before {
  content: "\f133" !important;
}
.fa-fire-extinguisher:before {
  content: "\f134" !important;
}
.fa-rocket:before {
  content: "\f135" !important;
}
.fa-maxcdn:before {
  content: "\f136" !important;
}
.fa-chevron-circle-left:before {
  content: "\f137" !important;
}
.fa-chevron-circle-right:before {
  content: "\f138" !important;
}
.fa-chevron-circle-up:before {
  content: "\f139" !important;
}
.fa-chevron-circle-down:before {
  content: "\f13a" !important;
}
.fa-html5:before {
  content: "\f13b" !important;
}
.fa-css3:before {
  content: "\f13c" !important;
}
.fa-anchor:before {
  content: "\f13d" !important;
}
.fa-unlock-alt:before {
  content: "\f13e" !important;
}
.fa-bullseye:before {
  content: "\f140" !important;
}
.fa-ellipsis-h:before {
  content: "\f141" !important;
}
.fa-ellipsis-v:before {
  content: "\f142" !important;
}
.fa-rss-square:before {
  content: "\f143" !important;
}
.fa-play-circle:before {
  content: "\f144" !important;
}
.fa-ticket:before {
  content: "\f145" !important;
}
.fa-minus-square:before {
  content: "\f146" !important;
}
.fa-minus-square-o:before {
  content: "\f147" !important;
}
.fa-level-up:before {
  content: "\f148" !important;
}
.fa-level-down:before {
  content: "\f149" !important;
}
.fa-check-square:before {
  content: "\f14a" !important;
}
.fa-pencil-square:before {
  content: "\f14b" !important;
}
.fa-external-link-square:before {
  content: "\f14c" !important;
}
.fa-share-square:before {
  content: "\f14d" !important;
}
.fa-compass:before {
  content: "\f14e" !important;
}
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150" !important;
}
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151" !important;
}
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152" !important;
}
.fa-euro:before,
.fa-eur:before {
  content: "\f153" !important;
}
.fa-gbp:before {
  content: "\f154" !important;
}
.fa-dollar:before,
.fa-usd:before {
  content: "\f155" !important;
}
.fa-rupee:before,
.fa-inr:before {
  content: "\f156" !important;
}
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157" !important;
}
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158" !important;
}
.fa-won:before,
.fa-krw:before {
  content: "\f159" !important;
}
.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a" !important;
}
.fa-file:before {
  content: "\f15b" !important;
}
.fa-file-text:before {
  content: "\f15c" !important;
}
.fa-sort-alpha-asc:before {
  content: "\f15d" !important;
}
.fa-sort-alpha-desc:before {
  content: "\f15e" !important;
}
.fa-sort-amount-asc:before {
  content: "\f160" !important;
}
.fa-sort-amount-desc:before {
  content: "\f161" !important;
}
.fa-sort-numeric-asc:before {
  content: "\f162" !important;
}
.fa-sort-numeric-desc:before {
  content: "\f163" !important;
}
.fa-thumbs-up:before {
  content: "\f164" !important;
}
.fa-thumbs-down:before {
  content: "\f165" !important;
}
.fa-youtube-square:before {
  content: "\f166" !important;
}
.fa-youtube:before {
  content: "\f167" !important;
}
.fa-xing:before {
  content: "\f168" !important;
}
.fa-xing-square:before {
  content: "\f169" !important;
}
.fa-youtube-play:before {
  content: "\f16a" !important;
}
.fa-dropbox:before {
  content: "\f16b" !important;
}
.fa-stack-overflow:before {
  content: "\f16c" !important;
}
.fa-instagram:before {
  content: "\f16d" !important;
}
.fa-flickr:before {
  content: "\f16e" !important;
}
.fa-adn:before {
  content: "\f170" !important;
}
.fa-bitbucket:before {
  content: "\f171" !important;
}
.fa-bitbucket-square:before {
  content: "\f172" !important;
}
.fa-tumblr:before {
  content: "\f173" !important;
}
.fa-tumblr-square:before {
  content: "\f174" !important;
}
.fa-long-arrow-down:before {
  content: "\f175" !important;
}
.fa-long-arrow-up:before {
  content: "\f176" !important;
}
.fa-long-arrow-left:before {
  content: "\f177" !important;
}
.fa-long-arrow-right:before {
  content: "\f178" !important;
}
.fa-apple:before {
  content: "\f179" !important;
}
.fa-windows:before {
  content: "\f17a" !important;
}
.fa-android:before {
  content: "\f17b" !important;
}
.fa-linux:before {
  content: "\f17c" !important;
}
.fa-dribbble:before {
  content: "\f17d" !important;
}
.fa-skype:before {
  content: "\f17e" !important;
}
.fa-foursquare:before {
  content: "\f180" !important;
}
.fa-trello:before {
  content: "\f181" !important;
}
.fa-female:before {
  content: "\f182" !important;
}
.fa-male:before {
  content: "\f183" !important;
}
.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184" !important;
}
.fa-sun-o:before {
  content: "\f185" !important;
}
.fa-moon-o:before {
  content: "\f186" !important;
}
.fa-archive:before {
  content: "\f187" !important;
}
.fa-bug:before {
  content: "\f188" !important;
}
.fa-vk:before {
  content: "\f189" !important;
}
.fa-weibo:before {
  content: "\f18a" !important;
}
.fa-renren:before {
  content: "\f18b" !important;
}
.fa-pagelines:before {
  content: "\f18c" !important;
}
.fa-stack-exchange:before {
  content: "\f18d" !important;
}
.fa-arrow-circle-o-right:before {
  content: "\f18e" !important;
}
.fa-arrow-circle-o-left:before {
  content: "\f190" !important;
}
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191" !important;
}
.fa-dot-circle-o:before {
  content: "\f192" !important;
}
.fa-wheelchair:before {
  content: "\f193" !important;
}
.fa-vimeo-square:before {
  content: "\f194" !important;
}
.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195" !important;
}
.fa-plus-square-o:before {
  content: "\f196" !important;
}
.fa-space-shuttle:before {
  content: "\f197" !important;
}
.fa-slack:before {
  content: "\f198" !important;
}
.fa-envelope-square:before {
  content: "\f199" !important;
}
.fa-wordpress:before {
  content: "\f19a" !important;
}
.fa-openid:before {
  content: "\f19b" !important;
}
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\f19c" !important;
}
.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\f19d" !important;
}
.fa-yahoo:before {
  content: "\f19e" !important;
}
.fa-google:before {
  content: "\f1a0" !important;
}
.fa-reddit:before {
  content: "\f1a1" !important;
}
.fa-reddit-square:before {
  content: "\f1a2" !important;
}
.fa-stumbleupon-circle:before {
  content: "\f1a3" !important;
}
.fa-stumbleupon:before {
  content: "\f1a4" !important;
}
.fa-delicious:before {
  content: "\f1a5" !important;
}
.fa-digg:before {
  content: "\f1a6" !important;
}
.fa-pied-piper-pp:before {
  content: "\f1a7" !important;
}
.fa-pied-piper-alt:before {
  content: "\f1a8" !important;
}
.fa-drupal:before {
  content: "\f1a9" !important;
}
.fa-joomla:before {
  content: "\f1aa" !important;
}
.fa-language:before {
  content: "\f1ab" !important;
}
.fa-fax:before {
  content: "\f1ac" !important;
}
.fa-building:before {
  content: "\f1ad" !important;
}
.fa-child:before {
  content: "\f1ae" !important;
}
.fa-paw:before {
  content: "\f1b0" !important;
}
.fa-spoon:before {
  content: "\f1b1" !important;
}
.fa-cube:before {
  content: "\f1b2" !important;
}
.fa-cubes:before {
  content: "\f1b3" !important;
}
.fa-behance:before {
  content: "\f1b4" !important;
}
.fa-behance-square:before {
  content: "\f1b5" !important;
}
.fa-steam:before {
  content: "\f1b6" !important;
}
.fa-steam-square:before {
  content: "\f1b7" !important;
}
.fa-recycle:before {
  content: "\f1b8" !important;
}
.fa-automobile:before,
.fa-car:before {
  content: "\f1b9" !important;
}
.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba" !important;
}
.fa-tree:before {
  content: "\f1bb" !important;
}
.fa-spotify:before {
  content: "\f1bc" !important;
}
.fa-deviantart:before {
  content: "\f1bd" !important;
}
.fa-soundcloud:before {
  content: "\f1be" !important;
}
.fa-database:before {
  content: "\f1c0" !important;
}
.fa-file-pdf-o:before {
  content: "\f1c1" !important;
}
.fa-file-word-o:before {
  content: "\f1c2" !important;
}
.fa-file-excel-o:before {
  content: "\f1c3" !important;
}
.fa-file-powerpoint-o:before {
  content: "\f1c4" !important;
}
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\f1c5" !important;
}
.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\f1c6" !important;
}
.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\f1c7" !important;
}
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8" !important;
}
.fa-file-code-o:before {
  content: "\f1c9" !important;
}
.fa-vine:before {
  content: "\f1ca" !important;
}
.fa-codepen:before {
  content: "\f1cb" !important;
}
.fa-jsfiddle:before {
  content: "\f1cc" !important;
}
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\f1cd" !important;
}
.fa-circle-o-notch:before {
  content: "\f1ce" !important;
}
.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: "\f1d0" !important;
}
.fa-ge:before,
.fa-empire:before {
  content: "\f1d1" !important;
}
.fa-git-square:before {
  content: "\f1d2" !important;
}
.fa-git:before {
  content: "\f1d3" !important;
}
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "\f1d4" !important;
}
.fa-tencent-weibo:before {
  content: "\f1d5" !important;
}
.fa-qq:before {
  content: "\f1d6" !important;
}
.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7" !important;
}
.fa-send:before,
.fa-paper-plane:before {
  content: "\f1d8" !important;
}
.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\f1d9" !important;
}
.fa-history:before {
  content: "\f1da" !important;
}
.fa-circle-thin:before {
  content: "\f1db" !important;
}
.fa-header:before {
  content: "\f1dc" !important;
}
.fa-paragraph:before {
  content: "\f1dd" !important;
}
.fa-sliders:before {
  content: "\f1de" !important;
}
.fa-share-alt:before {
  content: "\f1e0" !important;
}
.fa-share-alt-square:before {
  content: "\f1e1" !important;
}
.fa-bomb:before {
  content: "\f1e2" !important;
}
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\f1e3" !important;
}
.fa-tty:before {
  content: "\f1e4" !important;
}
.fa-binoculars:before {
  content: "\f1e5" !important;
}
.fa-plug:before {
  content: "\f1e6" !important;
}
.fa-slideshare:before {
  content: "\f1e7" !important;
}
.fa-twitch:before {
  content: "\f1e8" !important;
}
.fa-yelp:before {
  content: "\f1e9" !important;
}
.fa-newspaper-o:before {
  content: "\f1ea" !important;
}
.fa-wifi:before {
  content: "\f1eb" !important;
}
.fa-calculator:before {
  content: "\f1ec" !important;
}
.fa-paypal:before {
  content: "\f1ed" !important;
}
.fa-google-wallet:before {
  content: "\f1ee" !important;
}
.fa-cc-visa:before {
  content: "\f1f0" !important;
}
.fa-cc-mastercard:before {
  content: "\f1f1" !important;
}
.fa-cc-discover:before {
  content: "\f1f2" !important;
}
.fa-cc-amex:before {
  content: "\f1f3" !important;
}
.fa-cc-paypal:before {
  content: "\f1f4" !important;
}
.fa-cc-stripe:before {
  content: "\f1f5" !important;
}
.fa-bell-slash:before {
  content: "\f1f6" !important;
}
.fa-bell-slash-o:before {
  content: "\f1f7" !important;
}
.fa-trash:before {
  content: "\f1f8" !important;
}
.fa-copyright:before {
  content: "\f1f9" !important;
}
.fa-at:before {
  content: "\f1fa" !important;
}
.fa-eyedropper:before {
  content: "\f1fb" !important;
}
.fa-paint-brush:before {
  content: "\f1fc" !important;
}
.fa-birthday-cake:before {
  content: "\f1fd" !important;
}
.fa-area-chart:before {
  content: "\f1fe" !important;
}
.fa-pie-chart:before {
  content: "\f200" !important;
}
.fa-line-chart:before {
  content: "\f201" !important;
}
.fa-lastfm:before {
  content: "\f202" !important;
}
.fa-lastfm-square:before {
  content: "\f203" !important;
}
.fa-toggle-off:before {
  content: "\f204" !important;
}
.fa-toggle-on:before {
  content: "\f205" !important;
}
.fa-bicycle:before {
  content: "\f206" !important;
}
.fa-bus:before {
  content: "\f207" !important;
}
.fa-ioxhost:before {
  content: "\f208" !important;
}
.fa-angellist:before {
  content: "\f209" !important;
}
.fa-cc:before {
  content: "\f20a" !important;
}
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\f20b" !important;
}
.fa-meanpath:before {
  content: "\f20c" !important;
}
.fa-buysellads:before {
  content: "\f20d" !important;
}
.fa-connectdevelop:before {
  content: "\f20e" !important;
}
.fa-dashcube:before {
  content: "\f210" !important;
}
.fa-forumbee:before {
  content: "\f211" !important;
}
.fa-leanpub:before {
  content: "\f212" !important;
}
.fa-sellsy:before {
  content: "\f213" !important;
}
.fa-shirtsinbulk:before {
  content: "\f214" !important;
}
.fa-simplybuilt:before {
  content: "\f215" !important;
}
.fa-skyatlas:before {
  content: "\f216" !important;
}
.fa-cart-plus:before {
  content: "\f217" !important;
}
.fa-cart-arrow-down:before {
  content: "\f218" !important;
}
.fa-diamond:before {
  content: "\f219" !important;
}
.fa-ship:before {
  content: "\f21a" !important;
}
.fa-user-secret:before {
  content: "\f21b" !important;
}
.fa-motorcycle:before {
  content: "\f21c" !important;
}
.fa-street-view:before {
  content: "\f21d" !important;
}
.fa-heartbeat:before {
  content: "\f21e" !important;
}
.fa-venus:before {
  content: "\f221" !important;
}
.fa-mars:before {
  content: "\f222" !important;
}
.fa-mercury:before {
  content: "\f223" !important;
}
.fa-intersex:before,
.fa-transgender:before {
  content: "\f224" !important;
}
.fa-transgender-alt:before {
  content: "\f225" !important;
}
.fa-venus-double:before {
  content: "\f226" !important;
}
.fa-mars-double:before {
  content: "\f227" !important;
}
.fa-venus-mars:before {
  content: "\f228" !important;
}
.fa-mars-stroke:before {
  content: "\f229" !important;
}
.fa-mars-stroke-v:before {
  content: "\f22a" !important;
}
.fa-mars-stroke-h:before {
  content: "\f22b" !important;
}
.fa-neuter:before {
  content: "\f22c" !important;
}
.fa-genderless:before {
  content: "\f22d" !important;
}
.fa-facebook-official:before {
  content: "\f230" !important;
}
.fa-pinterest-p:before {
  content: "\f231" !important;
}
.fa-whatsapp:before {
  content: "\f232" !important;
}
.fa-server:before {
  content: "\f233" !important;
}
.fa-user-plus:before {
  content: "\f234" !important;
}
.fa-user-times:before {
  content: "\f235" !important;
}
.fa-hotel:before,
.fa-bed:before {
  content: "\f236" !important;
}
.fa-viacoin:before {
  content: "\f237" !important;
}
.fa-train:before {
  content: "\f238" !important;
}
.fa-subway:before {
  content: "\f239" !important;
}
.fa-medium:before {
  content: "\f23a" !important;
}
.fa-yc:before,
.fa-y-combinator:before {
  content: "\f23b" !important;
}
.fa-optin-monster:before {
  content: "\f23c" !important;
}
.fa-opencart:before {
  content: "\f23d" !important;
}
.fa-expeditedssl:before {
  content: "\f23e" !important;
}
.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: "\f240" !important;
}
.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241" !important;
}
.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242" !important;
}
.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243" !important;
}
.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244" !important;
}
.fa-mouse-pointer:before {
  content: "\f245" !important;
}
.fa-i-cursor:before {
  content: "\f246" !important;
}
.fa-object-group:before {
  content: "\f247" !important;
}
.fa-object-ungroup:before {
  content: "\f248" !important;
}
.fa-sticky-note:before {
  content: "\f249" !important;
}
.fa-sticky-note-o:before {
  content: "\f24a" !important;
}
.fa-cc-jcb:before {
  content: "\f24b" !important;
}
.fa-cc-diners-club:before {
  content: "\f24c" !important;
}
.fa-clone:before {
  content: "\f24d" !important;
}
.fa-balance-scale:before {
  content: "\f24e" !important;
}
.fa-hourglass-o:before {
  content: "\f250" !important;
}
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251" !important;
}
.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252" !important;
}
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253" !important;
}
.fa-hourglass:before {
  content: "\f254" !important;
}
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255" !important;
}
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\f256" !important;
}
.fa-hand-scissors-o:before {
  content: "\f257" !important;
}
.fa-hand-lizard-o:before {
  content: "\f258" !important;
}
.fa-hand-spock-o:before {
  content: "\f259" !important;
}
.fa-hand-pointer-o:before {
  content: "\f25a" !important;
}
.fa-hand-peace-o:before {
  content: "\f25b" !important;
}
.fa-trademark:before {
  content: "\f25c" !important;
}
.fa-registered:before {
  content: "\f25d" !important;
}
.fa-creative-commons:before {
  content: "\f25e" !important;
}
.fa-gg:before {
  content: "\f260" !important;
}
.fa-gg-circle:before {
  content: "\f261" !important;
}
.fa-tripadvisor:before {
  content: "\f262" !important;
}
.fa-odnoklassniki:before {
  content: "\f263" !important;
}
.fa-odnoklassniki-square:before {
  content: "\f264" !important;
}
.fa-get-pocket:before {
  content: "\f265" !important;
}
.fa-wikipedia-w:before {
  content: "\f266" !important;
}
.fa-safari:before {
  content: "\f267" !important;
}
.fa-chrome:before {
  content: "\f268" !important;
}
.fa-firefox:before {
  content: "\f269" !important;
}
.fa-opera:before {
  content: "\f26a" !important;
}
.fa-internet-explorer:before {
  content: "\f26b" !important;
}
.fa-tv:before,
.fa-television:before {
  content: "\f26c" !important;
}
.fa-contao:before {
  content: "\f26d" !important;
}
.fa-500px:before {
  content: "\f26e" !important;
}
.fa-amazon:before {
  content: "\f270" !important;
}
.fa-calendar-plus-o:before {
  content: "\f271" !important;
}
.fa-calendar-minus-o:before {
  content: "\f272" !important;
}
.fa-calendar-times-o:before {
  content: "\f273" !important;
}
.fa-calendar-check-o:before {
  content: "\f274" !important;
}
.fa-industry:before {
  content: "\f275" !important;
}
.fa-map-pin:before {
  content: "\f276" !important;
}
.fa-map-signs:before {
  content: "\f277" !important;
}
.fa-map-o:before {
  content: "\f278" !important;
}
.fa-map:before {
  content: "\f279" !important;
}
.fa-commenting:before {
  content: "\f27a" !important;
}
.fa-commenting-o:before {
  content: "\f27b" !important;
}
.fa-houzz:before {
  content: "\f27c" !important;
}
.fa-vimeo:before {
  content: "\f27d" !important;
}
.fa-black-tie:before {
  content: "\f27e" !important;
}
.fa-fonticons:before {
  content: "\f280" !important;
}
.fa-reddit-alien:before {
  content: "\f281" !important;
}
.fa-edge:before {
  content: "\f282" !important;
}
.fa-credit-card-alt:before {
  content: "\f283" !important;
}
.fa-codiepie:before {
  content: "\f284" !important;
}
.fa-modx:before {
  content: "\f285" !important;
}
.fa-fort-awesome:before {
  content: "\f286" !important;
}
.fa-usb:before {
  content: "\f287" !important;
}
.fa-product-hunt:before {
  content: "\f288" !important;
}
.fa-mixcloud:before {
  content: "\f289" !important;
}
.fa-scribd:before {
  content: "\f28a" !important;
}
.fa-pause-circle:before {
  content: "\f28b" !important;
}
.fa-pause-circle-o:before {
  content: "\f28c" !important;
}
.fa-stop-circle:before {
  content: "\f28d" !important;
}
.fa-stop-circle-o:before {
  content: "\f28e" !important;
}
.fa-shopping-bag:before {
  content: "\f290" !important;
}
.fa-shopping-basket:before {
  content: "\f291" !important;
}
.fa-hashtag:before {
  content: "\f292" !important;
}
.fa-bluetooth:before {
  content: "\f293" !important;
}
.fa-bluetooth-b:before {
  content: "\f294" !important;
}
.fa-percent:before {
  content: "\f295" !important;
}
.fa-gitlab:before {
  content: "\f296" !important;
}
.fa-wpbeginner:before {
  content: "\f297" !important;
}
.fa-wpforms:before {
  content: "\f298" !important;
}
.fa-envira:before {
  content: "\f299" !important;
}
.fa-universal-access:before {
  content: "\f29a" !important;
}
.fa-wheelchair-alt:before {
  content: "\f29b" !important;
}
.fa-question-circle-o:before {
  content: "\f29c" !important;
}
.fa-blind:before {
  content: "\f29d" !important;
}
.fa-audio-description:before {
  content: "\f29e" !important;
}
.fa-volume-control-phone:before {
  content: "\f2a0" !important;
}
.fa-braille:before {
  content: "\f2a1" !important;
}
.fa-assistive-listening-systems:before {
  content: "\f2a2" !important;
}
.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: "\f2a3" !important;
}
.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: "\f2a4" !important;
}
.fa-glide:before {
  content: "\f2a5" !important;
}
.fa-glide-g:before {
  content: "\f2a6" !important;
}
.fa-signing:before,
.fa-sign-language:before {
  content: "\f2a7" !important;
}
.fa-low-vision:before {
  content: "\f2a8" !important;
}
.fa-viadeo:before {
  content: "\f2a9" !important;
}
.fa-viadeo-square:before {
  content: "\f2aa" !important;
}
.fa-tshirt:before {
  content: "\f553" !important;
}
.fa-snapchat:before {
  content: "\f2ab" !important;
}
.fa-snapchat-ghost:before {
  content: "\f2ac" !important;
}
.fa-snapchat-square:before {
  content: "\f2ad" !important;
}
.fa-pied-piper:before {
  content: "\f2ae" !important;
}
.fa-first-order:before {
  content: "\f2b0" !important;
}
.fa-yoast:before {
  content: "\f2b1" !important;
}
.fa-themeisle:before {
  content: "\f2b2" !important;
}
.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "\f2b3" !important;
}
.fa-fa:before,
.fa-font-awesome:before {
  content: "\f2b4" !important;
}
.fa-handshake-o:before {
  content: "\f2b5" !important;
}
.fa-envelope-open:before {
  content: "\f2b6" !important;
}
.fa-envelope-open-o:before {
  content: "\f2b7" !important;
}
.fa-linode:before {
  content: "\f2b8" !important;
}
.fa-address-book:before {
  content: "\f2b9" !important;
}
.fa-address-book-o:before {
  content: "\f2ba" !important;
}
.fa-vcard:before,
.fa-address-card:before {
  content: "\f2bb" !important;
}
.fa-vcard-o:before,
.fa-address-card-o:before {
  content: "\f2bc" !important;
}
.fa-user-circle:before {
  content: "\f2bd" !important;
}
.fa-user-circle-o:before {
  content: "\f2be" !important;
}
.fa-user-o:before {
  content: "\f2c0" !important;
}
.fa-id-badge:before {
  content: "\f2c1" !important;
}
.fa-drivers-license:before,
.fa-id-card:before {
  content: "\f2c2" !important;
}
.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: "\f2c3" !important;
}
.fa-quora:before {
  content: "\f2c4" !important;
}
.fa-free-code-camp:before {
  content: "\f2c5" !important;
}
.fa-telegram:before {
  content: "\f2c6" !important;
}
.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: "\f2c7" !important;
}
.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: "\f2c8" !important;
}
.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: "\f2c9" !important;
}
.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: "\f2ca" !important;
}
.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: "\f2cb" !important;
}
.fa-shower:before {
  content: "\f2cc" !important;
}
.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: "\f2cd" !important;
}
.fa-podcast:before {
  content: "\f2ce" !important;
}
.fa-window-maximize:before {
  content: "\f2d0" !important;
}
.fa-window-minimize:before {
  content: "\f2d1" !important;
}
.fa-window-restore:before {
  content: "\f2d2" !important;
}
.fa-times-rectangle:before,
.fa-window-close:before {
  content: "\f2d3" !important;
}
.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: "\f2d4" !important;
}
.fa-bandcamp:before {
  content: "\f2d5" !important;
}
.fa-grav:before {
  content: "\f2d6" !important;
}
.fa-etsy:before {
  content: "\f2d7" !important;
}
.fa-imdb:before {
  content: "\f2d8" !important;
}
.fa-ravelry:before {
  content: "\f2d9" !important;
}
.fa-eercast:before {
  content: "\f2da" !important;
}
.fa-microchip:before {
  content: "\f2db" !important;
}
.fa-snowflake-o:before {
  content: "\f2dc" !important;
}
.fa-superpowers:before {
  content: "\f2dd" !important;
}
.fa-wpexplorer:before {
  content: "\f2de" !important;
}
.fa-meetup:before {
  content: "\f2e0" !important;
}
.fa-shapes:before {
  content: "\f61f" !important;
}
.fa-palette:before {
  content: "\f53f" !important;
}
.fa-icicles:before {
  content: "\f7ad" !important;
}
.fa-times-circle:before {
  content: "\f057" !important;
}
.fa-lock:before {
    content: "\f023";
}
.fa-unlock:before {
    content: "\f09c";
}
.fa-layer-group:before {
  content: "\f5fd";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-clock-rotate-left:before {
  content: "\f1da";
}
.fa-pen:before {
  content: "\f304";
}
.x-fa:before {
  font-family: FontAwesome !important;
}