

.menu-button {
	white-space: nowrap;
	line-height: 32px;
	color: var(--text-light-color);
    float: left;
	padding-right: 10px;
    text-align: start;
	display: flex;
	align-items: center;
}

.menu-button:hover {
    background-color: var(--panel-highlight-color);
    border-radius: 5px;
}

.menu-button label {
	font-family: 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
	font-size: 14px;
	margin-bottom: 0px;
}

.menu-button > div:first-child {
	display: inline-block;
    width: 40px;
    height: 50px;
	position: relative;
	text-align: center;
    font-family: 'FontAwesome';
	vertical-align: bottom;
}

.menu-button span {
	font-size: 45px;
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.disabled {
	opacity: 0.3;
	pointer-events: none;
	cursor: default;
}