

.main {
  width: 100vw;
  height: 3rem;
  padding-left: 0.5rem;
  background-color: #1d1d1d;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}

.main p {
  color: white;
  margin: 0;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  margin-right: 0.5rem;
}

.logoutContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0.5rem;
}

.activateUser {
  margin: 0 1rem;
}

.userDetails {
  display: flex;
  align-items: center;
}

.userDetails > div {
  margin-right: 0.25rem;
}

.notificationsContainer {
  margin-right: 0.5rem;
}

.notificationWrapper {
  max-width: 17rem;
  max-height: 25rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
}

.notifications {
  flex: 1;
  overflow-y: auto;
}

.notification {
  font-size: 0.75rem;
  padding: 0.5rem;
  display: flex;
}

.notification:hover {
  background-color: var(--panel-highlight-color);
}

.notificationActions {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.notificationActions  button {
  font-size: 0.7rem;
  padding: 0.25rem;
  height: 2rem;
}

.subscriptionMsg{
  animation: dimText 6s ease-in-out infinite;
}

@keyframes dimText {
  10% { opacity: 1; }
  15% { opacity: 0.9; }
  20% { opacity: 0.8; }
  25% { opacity: 0.7; }
  30% { opacity: 0.6; }
  35% { opacity: 0.5; }
  40% { opacity: 0.4; }
  45% { opacity: 0.3; }
  50% { opacity: 0.2; }
  55% { opacity: 0.3; }
  60% { opacity: 0.4; }
  65% { opacity: 0.5; }
  70% { opacity: 0.6; }
  75% { opacity: 0.7; }
  80% { opacity: 0.8; }
  85% { opacity: 0.9; }
  90% { opacity: 1; }
}