.main {
    margin: auto;
    margin-top: 1rem;
    width: 80%;
    height: 100%;
    background-color: var(--panel-semi-dark-background);
    color: var(--text-light-color);
    border-radius: 7px;
    padding: 2rem;
}

.main h2 {
    font-size: 1rem;
}

.header {
    display: flex;
    justify-content: space-between;
}

.searchBar {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.searchBar input {
    width: auto;
}

.searchBar label {
    margin: 0;
    margin-right: 0.5rem;
}

.tableWrapper {
    margin-top: 2rem;
    height: calc(100% - 3rem);
    overflow-y: auto;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: none;
}

.table th {
    padding: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
    width: 1%;
    white-space: nowrap;
}

.table th:nth-child(1){
    padding-left: 10px;
    padding-right: 10px;
}

.table tbody {
    z-index: 1;
}

.main th, .homeTable td {
    position: sticky;
    top: 0;
    background-color: var(--panel-semi-dark-background);
    color: var(--text-light-color);
    z-index: 1;
}

.addReviewerActions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.addReviewerContent {
    display: flex;
    flex-direction: column;
}

.addReviewerContent input {
    width: 100%;
}

.addReviewerActions button {
    width: 30%;
}

.addReviewerActions button:nth-child(2) {
    margin-left: 12px;
}

.addReviewerInput {
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
}

.addReviewerInput input[type="email"] {
    background-color: var(--text-light-color);
    color: black;
    border: none;
    padding-left: 0.5rem;
    width: 100%;
    height: 2rem;
    box-sizing: border-box;
}

.addReviewerInput > input:focus {
    outline: none;
}

.reviewers {
    margin-top: 20px;
}

.reviewersEmailListWrapper {
    margin-top: 10px;
    overflow-y: auto;
    height: 110px;
    background-color: var(--panel-semi-light-background);
}

.reviewerEmailList {
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin-top: 5px;
    padding: 0 5px;
    font-size: 0.8rem;
}

.reviewerEmails {
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchBox {
    display: flex;
    align-items: center;
    background-color: var(--text-light-color);
    padding: 0 15px 0 8px;
    border-radius: 20px;
}

.suggestionContainer {
    width: 100%;
    position: absolute;
    top: 30px;
    z-index: 1;
    max-height: 150px;
    overflow: auto;
    background-color: white;
    border-radius: 5px;
    margin-top: 3px;
}

.suggestionContainer::-webkit-scrollbar {
  width: 8px;
}

.suggestion {
    width: 100%;
    height: 30px;
    color: black;
    padding-left: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.active {
    background-color: #2684ff;
    color: white;
}

.refresh {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;
}

.table th > i {
    opacity: 0;
    margin-left: 5px;
}

.table th:hover > i {
    opacity: 0.5;
    transition: all 0.5s;
}

.down {
    transform: rotate(-180deg);
}

.wishlist {
    margin-left: 1rem;
}

.wishlist i {
  margin-left: 0.6rem;
  color: red;
}

.noProjects {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.noProjects div{
    margin-top: 1rem;
}

.noProjects a{
    color: var(--btn-primary-color);
}