

.input {
    width: 100%;
    background-color: var(--text-light-color);
    color: black;
    border: none;
    padding-left: 0.5rem;
    height: 2rem;   
}

.input:focus {
    outline: none;
}