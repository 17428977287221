@import "../../../styles.css";

.selection-indicator {
	width: 9px;
    height: 9px;
    border: 1px solid;
	cursor: pointer;
	display: inline-block;
}

.selection-indicator.selected{
	background-color: #389ae8;
}

.selection-indicator.disabled{
	opacity: 0.3;
	pointer-events: none;
	cursor: default;
}

.fabric-obj-view,
.lock-size {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	margin: 5px;
	margin-bottom: 0px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}

.fabric-obj-view {
	width: 2.5rem;
	height: 2.5rem;
}

.fabric-obj-name {
	width: 6rem !important; 
	font-size: 1rem !important;
}

.fabric-obj-view.black {
	background-color: rgba(234, 221, 221, 0.267);
}

table.layer-sequence {
	color: var(--text-light-color);
	border-spacing: 0;
}

table.layer-sequence td {
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	width: 2rem;
	font-size: 1.25rem;
	border-top: 1px solid #d0d0d0;
}

table.layer-sequence tr {
	line-height: 20px;
}

table.layer-sequence tr:first-child td {
	border-top: none;
}

.object-drag {
	font-size: 1.25rem;
	padding-left: 6.5px;
	margin-top: -1px;
	z-index: 2;
}

.object-drag > td {
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	width: 2rem;
}