.chat-box {
  width: 100%;
  display: flex;
}

.chat-box-left {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.chat-box-right {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.message-box {
  background-color: rgb(236, 246, 255);
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 6px;
  width: 70%;
  font-size: 0.8rem;
  color: black;
}

.message-box p {
  margin: 0;
}

.chat-box-right > .message-box {
  background-color: #cce7d2;
}

.msg-block {
  background-color: rgb(236, 246, 255);
  color: black;
  margin-bottom: 20px;
  padding: 8px 15px;
  border-radius: 0px 45px 0px 20px;
  width: 90%;
  animation: right 0.55s ease-in-out 1;
}

.msg-block-admin {
  background-color: rgb(236, 246, 255);
  color: black;
  margin-bottom: 20px;
  padding: 8px 15px;
  border-radius: 0px 45px 0px 20px;
  width: 90%;
  animation: right 0.55s ease-in-out 1;
}

.msg-block-logged {
  background-color: rgb(241, 255, 231);
  color: black;
  margin-bottom: 20px;
  padding: 8px 15px;
  border-radius: 45px 0px 20px 0px;
  width: 90%;
  margin-left: auto;
  animation: left 0.55s ease-in-out 1;
}

.dp {
  border-radius: 50%;
  width: 40px;
}

.msg-container {
  margin: -12px 10px 0px 12px;
}

.message {
  font-family: "Lato", sans-serif;
  color: rgb(47, 47, 47);
  letter-spacing: 0.7px;
  word-spacing: 1.5px;
}

.chat-flex {
  display: flex;
}

.chat-flex-logged {
  display: flex;
  flex-direction: row-reverse;
}

.date-time-container {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  text-align: end;
  color: rgba(0, 0, 0, 0.3);
}

.date-time-container-logged {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  text-align: start;
  color: rgba(0, 0, 0, 0.3);
}

.msg-block-loading {
  background-color: rgb(181 212 241);
  color: black;
  margin-bottom: 20px;
  padding: 8px 15px;
  border-radius: 0px 45px 0px 20px;
  width: 90%;
}

.msg-block-logged-loading {
  background-color: rgb(197 255 156);
  color: black;
  margin-bottom: 20px;
  padding: 8px 15px;
  border-radius: 45px 0px 20px 0px;
  width: 90%;
  margin-left: auto;
}

.left-dp {
  background-color: #9e9ecb !important;
}

.left-msg {
  background-color: #9e9ecb !important;
}

.left-date {
  background-color: #9e9ecb !important;
}

.right-dp {
  background-color: #7bb17f !important;
}

.right-msg {
  background-color: #7bb17f !important;
}

.right-date {
  background-color: #7bb17f !important;
}

.text > p {
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
  color: black;
}

.information-date-time {
  width: 100%;
  text-align: right;
  font-size: 0.6rem;
  font-style: italic;
}

.information-user {
  width: 100%;
  text-align: right;
  font-size: 0.7rem;
  font-style: italic;
}

.information-user span{
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: block;
}
