.grid>input {
    width: 0.75rem;
    height: 0.75rem;
}

.grid {
    display: grid;
    grid-gap: 0.2rem;
    grid-template-columns: repeat(3, 0fr);
}

.point {
    width: 0.75rem;
    height: 0.75rem;
    position: relative;
}

.point>input[type=checkbox] {
    display: none;
    cursor: pointer;
}

.point>input[type=checkbox] + label::after {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    border: 1px solid rgb(235, 220, 220);
}

.point>input[type=checkbox]:checked + label::after {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background: rgb(240, 234, 234);
}

.point>input[type=checkbox]:disabled + label::after {
    opacity: 0.5;
}
