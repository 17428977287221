.textBtn {
  min-width: 4rem;
  color: #0e5fbc;
  opacity: revert;
  font-size: revert;
  padding: revert;
}

.textBtn:disabled {
  color: gray;
  opacity: revert;
}

.textBtn:hover:not(:disabled) {
  background: #e6e2e2;
  color: #0e5fbc;
}

.textBtn:focus {
  outline: 0;
  box-shadow: none;
}
