

.main {
  height: calc(100% - 2rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--panel-light-background);
}

.textBoxEditor {
  width: 100%;
}

.chat {
  width: 100%;
  height: 100%;
}

.annotationsWrapper {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mainWithComment {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  overflow-y: auto;
}

.mainWithNoComment {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noComment {
  width: 19rem;
  height: 8rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noComment p {
  margin: 0;
}

.comment {
  width: 100%;
}
