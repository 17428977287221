

.mainBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    font-size: 0.75rem;
    background-color: var(--panel-semi-dark-background);
    border-radius: 8px;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     width: 17rem;
}

.heading {
    width: 100%;
}

.heading h2 {
    text-align: left;
    margin: 0;
    font-size: var(--heading-font-size);
    font-weight: var(--heading-font-weight);
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.container input[type="email"] {
    background-color: var(--text-light-color);
    color: black;
    border: none;
    padding-left: 0.5rem;
    width: 100%;
    height: 2rem;
    box-sizing: border-box;
}

.container input[type="email"]:focus {
    outline: none;
}

.container label {
    margin-top: 1rem;
}

.forgetPassword {
    margin-top: 1rem;
}

.signup {
    margin-top: 1rem;
    text-align: center;
}

.textBtn {
  min-width: 4rem;
  color: var(--btn-primary-color);
  background-color: transparent;
  border: none;
  outline: none;
}

.textBtn:hover{
  color: #5fa7fa;;
}

.actions {
    margin-top: 2rem;
    text-align: center;
}

.needHelp {
    margin-top: 0.5rem;
    text-align: center;
}