.chatroom {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-header {
  height: 3rem;
  width: 100%;
  background-color: var(--panel-semi-dark-background);
  padding: 0.5rem;
  box-sizing: border-box;
}

.chatlist {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.form {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#msgBox {
  width: calc(100% - 3rem);
  background-color: white;
  border: none;
  color: black;
  outline: none;
  height: 2.75rem;
  padding-left: 1rem;
  box-sizing: border-box;
}

#msgBox::placeholder {
  color: dimgray;
}

.send-btn {
  background-color: white;
  border: none;
}

.send-btn > img {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-right: 1rem;
}

.closed-thread-message{
  font-size: 0.7rem;
  width: 100%;
  padding: 3px 10px;
}