.searchContainer{
	display: flex;
	align-items: center;
	margin-right: 5px;
	position: relative;
}

.searchIcon {
	padding: 0 8px;
}

.searchContainerEnabled {
	background-color: var(--text-light-color);
	color: black;
	border-radius: 0 15px 15px 0;
}

.input {
	position: absolute;
	font-size: 14px;
	right: 30px;
 	width: 150px;
	height: 100%;
	background-color: var(--text-light-color);
	color: black;
	border: none;
	outline: none;
	padding: 0 0 0 15px;
	border-radius: 15px 0 0 15px;
}