@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.all-chats {
  background-color: var(--panel-light-background);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
  animation: fade 0.8s ease-in-out 1;
  box-sizing: border-box;
}
