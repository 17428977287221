.float-menu {
	display: block;
	user-select: none;
	padding: 10px;
	box-shadow: 0px 0px 30px #0008;	
    position: absolute;
    background: #fff;
	top: 0;
	left: 0;
	z-index: 1;
}