

.activateUserContent {
    display: flex;
    flex-direction: column;
}

.activateUserContent input {
  width: 100%;
}

.activateUserContent label {
  font-size: 0.75rem;
}

.activateUserActions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.activateUserActions button {
    width: 30%;
}

.activateUserActions button:nth-child(2) {
    margin-left: 12px;
}

.purchaseLicense{
    margin-bottom: 0;
    margin-top: 1rem;
}

.purchaseLicense a {
    color: var(--btn-primary-color);
}