

.main {
    color: var(--btn-primary-color);
    font-size: 0.75rem;
}

.main:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.main:hover {
    text-decoration: underline;
}

.disabled{
	pointer-events: none;
	opacity: 0.3;
	cursor:auto;
}