

.iconBtn {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.iconBtn:hover {
    background-color: var(--panel-semi-light-background);
}

.iconBtn:focus {
    outline: 0;
    box-shadow: none;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: rgb(75, 75, 75);
    font-size: 0.65rem;
    color: var(--text-light-color);
    text-align: center;
    padding: 0.25rem 0.75rem;
    border-radius: 0.2rem;
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltiptext {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
}

.btnWrapper[disabled] {
    pointer-events: none;
    opacity: 0.4;
}

.btnWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}