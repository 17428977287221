.main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.main span {
  font-size: 80%;
}

.main p {
  margin: auto;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.middleContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  text-align: center;
  font-size: 0.8rem;
}