.textureContainer {
    margin: 0 15px 0 9px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.textureName {
    margin-left: 5px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}